import AbstractValidator from './AbstractValidator';

export default class extends AbstractValidator {
    static targets = AbstractValidator.targets.concat([
        'startDate',
        'startTime',
        'endDate',
        'endTime',
    ]);

    static values = {
        endDateMustFollowStartDateString: {
            type: String,
            default:
                'The selected end date must follow the selected start date.',
        },
        endTimeMustFollowStartTimeString: {
            type: String,
            default:
                'The selected end time must follow the selected start time.',
        },
        allowEqual: Boolean,
        maxDays: Number,
        exceedsMaxDaysString: {
            type: String,
            default: 'The maximum number of days that can be selected is {d}.',
        },
    };

    get hasBothDates() {
        return this.hasStartDateTarget && this.hasEndDateTarget;
    }

    get hasBothTimes() {
        return this.hasStartTimeTarget && this.hasEndTimeTarget;
    }

    get startDateValue() {
        return this.hasStartDateTarget
            ? this.startDateTarget.datePickerController.value
            : null;
    }

    get startTimeValue() {
        if (!this.hasStartTimeTarget) {
            return null;
        }
        if (this.startTimeTarget?.timePickerController) {
            return this.startTimeTarget.timePickerController.getValue();
        }
        return this.startTimeTarget.value;
    }

    get endDateValue() {
        return this.hasEndDateTarget
            ? this.endDateTarget.datePickerController.value
            : null;
    }

    get endDateVisibleInput() {
        return this.hasEndDateTarget
            ? this.endDateTarget.datePickerController.focusable
            : null;
    }

    get endTimeValue() {
        if (!this.hasEndTimeTarget) {
            return null;
        }
        if (this.endTimeTarget?.timePickerController) {
            return this.endTimeTarget.timePickerController.getValue();
        }
        return this.endTimeTarget.value;
    }

    get endTimeVisibleInput() {
        if (!this.hasEndTimeTarget) {
            return null;
        }
        if (this.endTimeTarget?.timePickerController) {
            return this.endTimeTarget.timePickerController.getVisibleInput();
        }
        return this.endTimeTarget;
    }

    validate() {
        // Don't validate if this scope is within a div[data-validate-if-visible] which is hidden
        if (
            AbstractValidator.itemIsHiddenAndShouldNotBeValidated(this.element)
        ) {
            this.markFieldAsValid();
            return true;
        }

        if (this.hasBothDates) {
            // Date range (optionally with time)
            // Set as invalid if end date is before start date

            const startDate = this.startDateValue.replace(/-/g, '');
            const endDate = this.endDateValue.replace(/-/g, '');
            const bothDatesHaveValues = startDate !== '' && endDate !== '';
            if (bothDatesHaveValues) {
                if (endDate < startDate) {
                    this.markFieldAsInvalid(
                        this.endDateVisibleInput,
                        this.endDateMustFollowStartDateStringValue,
                    );
                    return false;
                } else if (
                    this.maxDaysValue &&
                    daysInRange(this.startDateValue, this.endDateValue) >
                        this.maxDaysValue
                ) {
                    this.markFieldAsInvalid(
                        this.endDateVisibleInput,
                        this.exceedsMaxDaysStringValue.replace(
                            '{d}',
                            this.maxDaysValue,
                        ),
                    );
                    return false;
                }
            }
            this.markInputAsValid(this.endDateVisibleInput);
        }
        if (this.hasBothTimes) {
            // Time range (optionally with dates)
            // Set as invalid if end time is before/equal to start time
            // Equal is permitted if allowEqualValue is true
            const datesAreEqual =
                this.hasBothDates && this.startDateValue === this.endDateValue;
            const startTime = this.startTimeValue.replace(/:/g, '');
            const endTime = this.endTimeValue.replace(/:/g, '');
            const allowEqual = this.allowEqualValue;
            if (
                startTime !== '' &&
                endTime !== '' &&
                (!this.hasBothDates || datesAreEqual) &&
                (endTime < startTime || (!allowEqual && endTime === startTime))
            ) {
                this.markFieldAsInvalid(
                    this.endTimeVisibleInput,
                    this.endTimeMustFollowStartTimeStringValue,
                );
                return false;
            }
            this.markInputAsValid(this.endTimeVisibleInput);
        }

        this.markFieldAsValid();
        return true;
    }

    getCounterpartEl(el) {
        switch (el) {
            case this.startDateTarget: {
                return this.endDateTarget;
            }
            case this.startTimeTarget: {
                return this.endTimeTarget;
            }
            case this.endDateTarget: {
                return this.startDateTarget;
            }
            case this.endTimeTarget: {
                return this.endTimeTarget;
            }
        }
        return null;
    }

    getRoleOfEl(el) {
        switch (el) {
            case this.startDateTarget:
            case this.startTimeTarget: {
                return 'start';
            }
            case this.endDateTarget:
            case this.endTimeTarget: {
                return 'end';
            }
        }
        return null;
    }
}

/**
 * Calculates the number of days in the range between two dates given in YYYY-MM-DD format.
 * The range is inclusive of both the start and end dates.
 *
 * @param {string} dateStr1 - The first date in YYYY-MM-DD format.
 * @param {string} dateStr2 - The second date in YYYY-MM-DD format.
 * @returns {number} The number of days in the inclusive range.
 */
function daysInRange(dateStr1, dateStr2) {
    // Convert the date strings to Date objects.
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    // Calculate the absolute difference in milliseconds.
    const diffInMs = Math.abs(date2 - date1);

    // Number of milliseconds in one day.
    const msPerDay = 1000 * 60 * 60 * 24;

    // Convert the difference in milliseconds to days, add 1 for inclusive range.
    return Math.round(diffInMs / msPerDay) + 1;
}
