import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['select'];

    static values = {
        exclude: Array, // options with the values in this array won't be deduped/disabled
    };

    change() {
        // First, re-enable all options in every select element
        this.selectTargets.forEach((select) => {
            Array.from(select.options).forEach((option) => {
                option.disabled = false;
            });
        });

        // For each select element, disable the option selected in it for all other selects
        this.selectTargets.forEach((currentSelect) => {
            const selectedVal = currentSelect.value;
            if (selectedVal && !this.excludeValue.includes(selectedVal)) {
                this.selectTargets.forEach((otherSelect) => {
                    if (otherSelect !== currentSelect) {
                        const optionToDisable = otherSelect.querySelector(
                            `option[value="${selectedVal}"]`,
                        );
                        if (optionToDisable) {
                            optionToDisable.disabled = true;
                        }
                    }
                });
            }
        });
    }
}
